const gql = String.raw;
  
export const Person = gql`
query PersonBySlugQuery($slug: ID!) {
  personBySlug(slug: $slug) {
    
      
    
        id name slug typeLabel badge path updated __typename _schema { label pluralLabel }
    
      
      
            openGraph { 
              id title 
              description { plain text }
              image { 
                id url url2x 
                thumbnails { 
                  id bubbleLarge { id url url2x }
      mainBanner { id url url2x }
      halfBanner { id url url2x }
                }
                
              } 
            }
          
      
        entityType { useAi }
        entityViews {
          id name hash label slug count showCount
          type { id name slug }
          slices {
            id name slug label updated
            title 
            titleRich { id text }
            layout { id name slug component }
            themeMode { id name slug }
            openGraph {
              id
              image { url thumbnails { large { url } bubble { url url2x } } }
            }
            emptyTout { id text }
            ownEmptyTout { id text }
            entityFormFields {
              name
              label
              description
              ownDescription
              type
              formFieldType
              entityTypeName
              entityTypeNamePlural
            }
            headerFontType { internalName }
            bodyFontType { internalName }
            disabled
            connectedEntities { name label internalName }
            entityLayout { internalName }
            created updated
            outro { id text }
            intro { id text }
            content { id plain text }
            email
            phone
            twitter
            threads
            linkedin
            backgroundColor { id value }
            color { id value }
            teams { id name slug __typename _schema { label pluralLabel } }
            objectives { id name slug __typename type { id name slug __typename } }
            icon { id slug name component }
            people {
              id name slug path __typename
              organisation { id name }
              linkedin
              position
              openGraph { id image { thumbnails { bubble { url url2x } card { url url2x } } } }
            }
            indicators { 
              id name slug title
            }
            indicatorGroups { 
              id slug name 
            }
            economies {
              id name slug path __typename _schema { label pluralLabel }
              openGraph { id image { url url2x thumbnails { bubble { url url2x } } } }
            } 
            locations { 
              id name slug __typename _schema { label pluralLabel } 
              openGraph { id image { url url2x thumbnails { bubble { url url2x } } } }
            }
            posts { 
              id name slug path __typename _schema { label  pluralLabel }
              type { id name slug }
              typeLabel
              openGraph { id image { url url2x thumbnails { bubble { url url2x } } } }
            }
            feedEntries {
              name id slug path url __typename _schema { label pluralLabel }
              type { id name slug }
              typeLabel
              openGraph { id title image { url thumbnails { full { url } } } }
            }
            pages {
              id name slug path __typename _schema { label pluralLabel }
              type { id name slug }
              typeLabel
              openGraph { id image { url url2x thumbnails { bubble { url url2x } } } }
            }
            organisations { 
              id name slug path __typename _schema { label pluralLabel }
              openGraph { id image { url url2x thumbnails { bubble { url url2x } card { url url2x } } } }
            }
            topicExpertise {
              
                id __typename typeLabel badge name slug
                levelOfExpertise { id name slug }
                topics { 
                  __typename outlineNumber id name slug path 
                  trend { id slug }
                  openGraph {
                    id
                    title description { plain }
                    image { url url2x thumbnails { medium { url url2x } } }
                  }
                }
              
            }
            trendExpertise {
              
                id __typename typeLabel badge name slug
                levelOfExpertise { id name slug }
                trends { 
                  __typename outlineNumber id path name slug
                  openGraph { 
                    id title description { plain } image { url url2x thumbnails { medium { url url2x } } }
                  }
                }
              
            }
          }
        }
      
      hideEmail
      hidePhone
      position
      email
      phone
      twitter
      threads
      linkedin
      bio { id text }
      type { id name slug }
      positions { id name }
      position
      organisation { id name slug }
      organisations {
        
          
        
            id name slug typeLabel badge path updated __typename _schema { label pluralLabel }
        
          
          
                openGraph { 
                  id title 
                  
                  image { 
                    id url url2x 
                    thumbnails { 
                      id bubbleMedium { id url url2x }
                    }
                    
                  } 
                }
              
        
        type { id name slug }
      }
      colleagues {
        id name slug path __typename _schema { label pluralLabel }
        position
        organisation { id name }
        
          
        
            id name slug typeLabel badge path updated __typename _schema { label pluralLabel }
        
          
          
                openGraph { 
                  id title 
                  
                  image { 
                    id url url2x 
                    thumbnails { 
                      id bubbleMedium { id url url2x }
                    }
                    
                  } 
                }
              
        
      }
      economy { 
        id name slug __typename _schema { label pluralLabel }
        openGraph { id image { id url url2x thumbnails { id bubble { id url url2x } card { id url url2x } } } }
      }
      
    
    badge
    hasConnectedTopics
    relatedEvents {
      id name title slug path __typename _schema { label pluralLabel }
      type { id name slug }
      openGraph { 
        id
        image { id url url2x } 
      }
    }
    pages {
      id name slug path __typename _schema { label pluralLabel }
      openGraph { 
        id image { id url url2x } 
      }
    }
    trendExpertise {
      
        id __typename typeLabel badge name slug
        levelOfExpertise { id name slug }
        trends { 
          __typename outlineNumber id path name slug
          openGraph { 
            id title description { plain } image { url url2x thumbnails { medium { url url2x } } }
          }
        }
      
    }
    topicExpertise {
      
        id __typename typeLabel badge name slug
        levelOfExpertise { id name slug }
        topics { 
          __typename outlineNumber id name slug path 
          trend { id slug }
          openGraph {
            id
            title description { plain }
            image { url url2x thumbnails { medium { url url2x } } }
          }
        }
      
      id edition { id name }
      badge
    }
  }
}
`;
