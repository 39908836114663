import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { Event } from '@shapeable/gesda-types';
import { SiteHeaderLayout, ComponentPropsProvider, PageHalfBanner, EntityTypeBannersProvider, ExplorerEntityDetailsLayout, ExplorerEntityDetailsLayoutDefaultProps, ExplorerEntityDetailsLayoutProps, SliceLayoutBoundary, useView, ExplorerTabs } from '@shapeable/ui';
import { ExplorerProvider } from '../providers/explorer-provider';
import { EventTabsLayout } from './event-tabs-layout';
import { EventViewLayout } from './event-view-layout';
import { EventMetaLayout } from './event-meta-layout';
import { EventView } from '../../types';
const cls = classNames('event-explorer-layout');

// -------- Types -------->

// -------- Props -------->

export type EventExplorerLayoutProps = ExplorerEntityDetailsLayoutProps & { 
  entity?: Event;
};

export const EventExplorerLayoutDefaultProps: Omit<EventExplorerLayoutProps, 'entity'> = {
  ...ExplorerEntityDetailsLayoutDefaultProps,
};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});

const LayoutStyles = breakpoints({
  base: css`
  `,
});

const HeaderStyles = breakpoints({
  base: css`
    
  `,
});

const TabsStyles = breakpoints({
  base: css`
    
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Header: styled(SiteHeaderLayout)`${HeaderStyles}`,
    Tabs: styled(ExplorerTabs)`${TabsStyles}`,
    Layout: styled(ExplorerEntityDetailsLayout)`${LayoutStyles}`,
  
};

export const EventExplorerLayout: Shapeable.FC<EventExplorerLayoutProps> = (props) => {
  const { className, children, entity } = props;
  
  const { showView } = useView<EventView>(entity.slug, 'sessions');
  

  return (
    <ExplorerProvider>
    <ComponentPropsProvider value={{
      EntityBanner: {
        variant: 'image',
        showSubheader: true,
        useBackgroundColor: false,
        entityValueProps: { showImage: false, showOutlineNumber: false }
      },
      SessionLayout: {
        showDownloadLinks: false,
      }
    }}>
    <My.Container>
      <My.Header />
      <My.Tabs useStickyStyle />
      <SliceLayoutBoundary boundary='none' >
      <My.Layout 
        entity={entity}
        className={cls.name(className)}
        stickyTopOffset={64}
        asideMaxWidth={800}
        isAsideSticky={false}

        // main
        tabs={
          <EventTabsLayout entity={entity} />
        }
        content={
          <EventViewLayout entity={entity} />
        }

        showMetaHead={(target) => target === 'aside' || showView('overview')} 
        // aside 
        metaHead={
          <EventMetaLayout entity={entity} />
        }
        />
        </SliceLayoutBoundary>
    </My.Container>
    </ComponentPropsProvider>
    </ExplorerProvider>
  )
};

EventExplorerLayout.defaultProps = EventExplorerLayoutDefaultProps;
EventExplorerLayout.cls = cls;