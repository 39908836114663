import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { Trend } from '@shapeable/gesda-types';
import { TrendTabsLayout } from './trend-tabs-layout';
import { TrendViewLayout } from './trend-view-layout';
// import { RadarChart } from '@shapeable/gesda-ui';
import { DarkMode, ExplorerTabs, SiteHeaderLayout } from '@shapeable/ui';
import { BackButtonEntityProvider, ComponentPropsProvider, ExplorerEntityDetailsLayout, ExplorerEntityDetailsLayoutDefaultProps, ExplorerEntityDetailsLayoutProps, PAGE_HOME, SliceLayoutBoundary } from '@shapeable/ui';
import { ExplorerProvider } from '../providers/explorer-provider';
import { useRadarMap } from '../../hooks/use-radar-map';
import { RadarChartMain } from '../elements/radar-chart-main';
import { RadarChart } from '../elements/radar-chart';
const cls = classNames('trend-explorer-layout');

// -------- Types -------->

// -------- Props -------->

export type TrendExplorerLayoutProps = ExplorerEntityDetailsLayoutProps & { 
  entity?: Trend;
};

export const TrendExplorerLayoutDefaultProps: Omit<TrendExplorerLayoutProps, 'entity'> = {
  ...ExplorerEntityDetailsLayoutDefaultProps,
};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});

const LayoutStyles = breakpoints({
  base: css`
  `,
});

const HeaderStyles = breakpoints({
  base: css`
    
  `,
});

const RadarChartStyles = breakpoints({
  base: css`
  `,
});

const TabsStyles = breakpoints({
  base: css`
    
  `,
});



// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Header: styled(SiteHeaderLayout)`${HeaderStyles}`,
    Tabs: styled(ExplorerTabs)`${TabsStyles}`,
    Layout: styled(ExplorerEntityDetailsLayout)`${LayoutStyles}`,
      RadarChart: styled(RadarChart)`${RadarChartStyles}`,

    
};

export const TrendExplorerLayout: Shapeable.FC<TrendExplorerLayoutProps> = (props) => {
  const { className, children, entity } = props;

  const { isShown } = useRadarMap();
  
  return (
    <ExplorerProvider>
    <BackButtonEntityProvider value={PAGE_HOME}>
    <ComponentPropsProvider value={{
      EntityBanner: {
        maxHeight: 300,
        variant: 'overlay',
        customAspectRatio: { base: 16 / 9, desktop: 21 / 9 },
        entityValueProps: { showImage: false, showOutlineNumber: true, outlineBubble: { numberSize: 0.6, showBorder: true, showImage: false }}
      },
    }}>
    <My.Container>
      <My.Header />
      <My.Tabs useStickyStyle />
      <SliceLayoutBoundary boundary='none' >
      <My.Layout 
        entity={entity}
        className={cls.name(className)}
        asideIsRevealed={isShown}
        stickyTopOffset={117}

        //main
        tabs={
          <TrendTabsLayout entity={entity} />
        }
        content={
          <TrendViewLayout entity={entity} />
        }
        dataVizMain={
          <RadarChartMain />
        }

        //aside
        dataViz={
          <My.RadarChart />
        }
        />
        </SliceLayoutBoundary>
    </My.Container>
    </ComponentPropsProvider>
    </BackButtonEntityProvider>
    </ExplorerProvider>
  )
};

TrendExplorerLayout.defaultProps = TrendExplorerLayoutDefaultProps;
TrendExplorerLayout.cls = cls;