import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { Topic } from '@shapeable/gesda-types';
import { SliceLayoutBoundary, SiteHeaderLayout, ExplorerEntityDetailsLayoutDefaultProps, ExplorerEntityDetailsLayout, ComponentPropsProvider, ExplorerEntityDetailsLayoutProps, BackButtonEntityProvider, ExplorerTabs, } from '@shapeable/ui';
import { ExplorerProvider } from '../providers/explorer-provider';
import { TopicTabsLayout } from './topic-tabs-layout';
import { TopicViewLayout } from './topic-view-layout';
import { useRadarMap } from '../../hooks/use-radar-map';
import { TopicBannerLayout } from './topic-banner-layout';
import { RadarChartMain } from '../elements/radar-chart-main';
import { RadarChart } from '../elements/radar-chart';
const cls = classNames('topic-banner-layout');

// -------- Types -------->

// -------- Props -------->

export type TopicExplorerLayoutProps = ExplorerEntityDetailsLayoutProps & { 
  entity?: Topic;
};

export const TopicExplorerLayoutDefaultProps: Omit<TopicExplorerLayoutProps, 'entity'> = {
  ...ExplorerEntityDetailsLayoutDefaultProps,
};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});

const LayoutStyles = breakpoints({
  base: css`
  `,
});

const HeaderStyles = breakpoints({
  base: css`
    
  `,
});

const RadarChartStyles = breakpoints({
  base: css`
  `,
});

const TabsStyles = breakpoints({
  base: css`
    
  `,
});

// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Header: styled(SiteHeaderLayout)`${HeaderStyles}`,
    Tabs: styled(ExplorerTabs)`${TabsStyles}`,
    Layout: styled(ExplorerEntityDetailsLayout)`${LayoutStyles}`,
      RadarChart: styled(RadarChart)`${RadarChartStyles}`,

};

export const TopicExplorerLayout: Shapeable.FC<TopicExplorerLayoutProps> = (props) => {
  const { className, children, entity } = props;
  const { isShown } = useRadarMap();

  return (
    <ExplorerProvider>
    <BackButtonEntityProvider value={entity.trend}>
    <My.Container>
      <My.Header />
      <My.Tabs useStickyStyle />
      <SliceLayoutBoundary boundary='none' >
      <My.Layout 
        entity={entity}
        className={cls.name(className)}
        asideIsRevealed={isShown}
        stickyTopOffset={117}
        
        tabs={
          <TopicTabsLayout entity={entity} />
        }
        content={
          <TopicViewLayout entity={entity} />
        }
        dataVizMain={
          <RadarChartMain />
        }

        dataViz={
          <My.RadarChart />
        }
        />
        </SliceLayoutBoundary>
    </My.Container>
    </BackButtonEntityProvider>
    </ExplorerProvider>
  )
};

TopicExplorerLayout.defaultProps = TopicExplorerLayoutDefaultProps;
TopicExplorerLayout.cls = cls;