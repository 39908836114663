import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { AiLowerCaseIconGlyph, GridIconGlyph, RadarIconGlyph } from '@shapeable/icons';
import { ExplorerTabs, ExplorerTabsSpec, SiteFooterLayout, useReady, useView } from '@shapeable/ui';
import { ExplorerHomeLayout } from './explorer-home-layout';
import { ExplorerGptLayout } from './explorer-gpt-layout';
import { ExplorerCommunityLayout } from './explorer-community-layout';
import { ExplorerResourcesLayout } from './explorer-resources-layout';
import { ExplorerPulseOfScienceLayout } from './explorer-pulse-of-science-layout';
import { ExplorerPulseOfSocietyLayout } from './explorer-pulse-of-society-layout';
import { ExplorerPulseOfDiplomacyLayout } from './explorer-pulse-of-diplomacy-layout';
import { ExplorerPulseOfImpactLayout } from './explorer-pulse-of-impact-layout';
import { ExplorerView } from '../../types';
const cls = classNames('explorer-layout');

// -------- Types -------->

export type ExplorerIndexLayoutProps = Classable & HasChildren & { 
  entity?: Entity;
};

export const ExplorerIndexLayoutDefaultProps: Omit<ExplorerIndexLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
  `,
});

const ViewStyles = breakpoints({
  base: css`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    background-color: ${theme.COLOR('ExplorerIndexLayout.background', 'brightWarm')};
  `,
});

const TabsStyles = breakpoints({
  base: css`
  `,
});

const GptStyles = breakpoints({
  base: css`
    
  `,
});

const FooterLayoutStyles = breakpoints({
  base: css`
    
  `,
});

const HomeLayoutStyles = breakpoints({
  base: css`
  `,
});

const PulseOfScienceLayoutStyles = breakpoints({
  base: css`
    
  `,
});

const OpportunitiesLayoutStyles = breakpoints({
  base: css`
    
  `,
});

const DebatesLayoutStyles = breakpoints({
  base: css`
    
  `,
});

const CommunityLayoutStyles = breakpoints({
  base: css`
    
  `,
});

const ImpactLayoutStyles = breakpoints({
  base: css`
    
  `,
});



// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Tabs: styled(ExplorerTabs)`${TabsStyles}`,
      View: styled.div`${ViewStyles}`,
        GptLayout: styled(ExplorerGptLayout)`${GptStyles}`,
        HomeLayout: styled(ExplorerHomeLayout)`${HomeLayoutStyles}`,
        PulseOfScienceLayout: styled(ExplorerPulseOfScienceLayout)`${PulseOfScienceLayoutStyles}`,
        PulseOfDiplomacyLayout: styled(ExplorerPulseOfDiplomacyLayout)`${OpportunitiesLayoutStyles}`,
        PulseOfImpactLayout: styled(ExplorerPulseOfImpactLayout)`${ImpactLayoutStyles}`,
        PulseOfSocietyLayout: styled(ExplorerPulseOfSocietyLayout)`${DebatesLayoutStyles}`,
        CommunityLayout: styled(ExplorerCommunityLayout)`${CommunityLayoutStyles}`,
        Resources: styled(ExplorerResourcesLayout)`${CommunityLayoutStyles}`,

    Footer: styled(SiteFooterLayout)`${FooterLayoutStyles}`,
};

export const ExplorerIndexLayout: Shapeable.FC<ExplorerIndexLayoutProps> = (props) => {
  const { className, children, entity } = props;

  const { showView, isActiveView } = useView<ExplorerView>('radar', 'radar');

  const isReady = useReady();

  // const tabs: ExplorerTabsSpec<ExplorerView>[] = [
  //   {
  //     id: 'ai',
  //     icon: <AiLowerCaseIconGlyph size={24} />
  //   },
  //   {
  //     id: 'radar',
  //     label: 'Radar',
  //     icon: <RadarIconGlyph size={24} />
  //   },
  //   {
  //     id: 'pulse-of-science',
  //     label: 'Pulse of Science',
  //     icon: <GridIconGlyph />
  //   },
  //   {
  //     id: 'pulse-of-diplomacy',
  //     label: 'Pulse of Diplomacy',
  //     icon: <GridIconGlyph />
  //   },
  //   {
  //     id: 'pulse-of-impact',
  //     label: 'Pulse of Impact',
  //     icon: <GridIconGlyph />
  //   },
  //   {
  //     id: 'pulse-of-society',
  //     label: 'Pulse of Society',
  //     icon: <GridIconGlyph />
  //   },
  //   {
  //     id: 'community',
  //     label: 'Community',
  //     icon: <GridIconGlyph />,
  //   },
  //   {
  //     id: 'resources',
  //     label: 'Resources',
  //     icon: <GridIconGlyph />
  //   },
  // ];

  return (
    <>
    <My.Container className={cls.name(className)}>
      {
        isReady &&
        <My.Tabs useStickyStyle defaultView='radar' />
      }
      <My.View>
      {
        isActiveView('ai') && 
        <My.GptLayout entity={entity} />
      }
      {
        isActiveView('radar') && 
        <My.HomeLayout entity={entity} />
      }
      {
        isActiveView('pulse-of-science') && 
        <My.PulseOfScienceLayout />
      }
      {
        isActiveView('pulse-of-diplomacy') && 
        <My.PulseOfDiplomacyLayout />
      }
      {
        isActiveView('pulse-of-impact') && 
        <My.PulseOfImpactLayout />
      }
      {
        isActiveView('pulse-of-society') &&
        <My.PulseOfSocietyLayout />
      }
      {
        isActiveView('community') &&
        <My.CommunityLayout />
      }
      {
        isActiveView('resources') &&
        <My.Resources />
      }
      </My.View>
    </My.Container>
    </>
  );
};

ExplorerIndexLayout.defaultProps = ExplorerIndexLayoutDefaultProps;
ExplorerIndexLayout.cls = cls;